import { useCallback, useEffect, useState } from 'react';
import cookieHandler from '@/utils/cookies';
import { useRouter } from 'next/router';
import { addDays, startOfDay } from 'date-fns';
import { getSessionItem, setSessionItem } from '@store/base';

const MAIN_POPUP_KEY = 'MAIN_POPUP';

const useMainPopup = () => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [isReadyData, setIsReadyData] = useState(false);
  const { get, set } = cookieHandler();

  const handleSyncData = useCallback((readyState: boolean) => {
    setIsReadyData(readyState);
  }, []);

  const handleClickContent = useCallback(
    (linkUrl: string) => {
      router.push(linkUrl);
    },
    [router],
  );

  const handleClosePopup = useCallback(() => {
    setSessionItem(MAIN_POPUP_KEY, 'done', 60);
    setIsOpen(false);
  }, []);

  const handleCloseToday = useCallback(() => {
    const now = new Date();
    const expires = startOfDay(addDays(now, 1));

    set(MAIN_POPUP_KEY, 'done', {
      domain: `${process.env.NEXT_PUBLIC_COOKIE_DOMAIN}`,
      expires,
    });
    setIsOpen(false);
  }, [set]);

  useEffect(() => {
    if (
      !get(MAIN_POPUP_KEY) &&
      !getSessionItem(MAIN_POPUP_KEY) &&
      isReadyData
    ) {
      setIsOpen(true);
    }
  }, [get, isReadyData]);

  return {
    isOpen,
    isReadyData,
    handleClickContent,
    handleClosePopup,
    handleCloseToday,
    handleSyncData,
  };
};

export default useMainPopup;
